<template>
  <v-list color="white" class="rounded">
    <template v-for="({ divider, visible, to, disabled, title}, index) in links">
      <v-divider v-if="divider && visible" :key="index"></v-divider>

      <v-list-item v-else-if="visible" :ripple="false" :key="index" :to="to" :disabled="disabled" active-class="current">
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavList',

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
a.current.v-list-item--active {
  background-color: white;
  font-weight: bold;

  &::before {
    content: unset;
  }
}
</style>
